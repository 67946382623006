import React  from "react";
import { Button, Modal } from '@themesberg/react-bootstrap';


const Toasts = (props) => {
    const {show, setShow, message} = props;
    return (
        <React.Fragment>
            <Modal as={Modal.Dialog} centered show={show}>
                <Modal.Header>
                    <Modal.Title className="h6">Ops 😟</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{width: '100%'}}>
                        <p style={{textAlign: 'center'}}>Contattaci per ulteriori informazioni: <a href="mailto:certificati@lametsrl.com">certificati@lametsrl.com</a> </p>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default Toasts;
