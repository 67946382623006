export const PASSWORD_REQUEST = 'PASSWORD_REQUEST';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const LOGIN_REQUEST_REQUEST = 'LOGIN_REQUEST_REQUEST';
export const VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST';
export const LOGOUT = 'LOGOUT';
export const GET_PDF_REQUEST = 'GET_PDF_REQUEST';
export const GET_CERTIFICATES = 'GET_CERTIFICATES';



