
import React from "react";
import { Navbar, Container} from '@themesberg/react-bootstrap';
import {useSelector} from "react-redux";


export default (props) => {
  const {auth} = useSelector((state) => state);

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 nav-top-bar">
      <Container fluid className="px-0 justify-content-center">
            <span className="font-medium fw-bold">{auth?.data?.first_name + ' ' + auth?.data?.last_name}</span>
      </Container>
    </Navbar>
  );
};
