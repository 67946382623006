
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import {Provider as StoreProvider} from 'react-redux';
import store, {persistor} from './redux/storeConfig';
import {PersistGate} from 'redux-persist/integration/react';

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
    <StoreProvider store={store}>
        <PersistGate persistor={persistor} loading={false}>
            <HashRouter>
                <ScrollToTop />
                <HomePage />
            </HashRouter>
        </PersistGate>
    </StoreProvider>,
  document.getElementById("root")
);
