export const baseURL = 'https://xml-dashboard-backend.herokuapp.com';
 // export const baseURL =  'http://localhost:3000'


export const endPoints = {
  authSignUp: baseURL + '/signup',
  passwordRequest: baseURL + '/passwordRequest',
  authSignIn: baseURL + '/login',
  validateToken: baseURL + '/validateToken',
  getPDF: (q) => baseURL + `/getPDF?id=${q}`,
  getCertificates: (q) => baseURL + `/getCertificates?year=${q}`,
};
