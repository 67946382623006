import React, {useEffect, useState} from "react";
import { Button, Table, Modal} from '@themesberg/react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {getPDF, getCertificates} from "../../redux/actions";
import Preloader from "../../components/Preloader";
import Iframe from 'react-iframe'
import {Form, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faDownload, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toasts from "../../components/Toast";

export default () => {
  const [loaded, setLoaded] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const dispatch = useDispatch();
  const {auth} = useSelector((state) => state);
  const [showUrl, setShowUrl] = useState();
  const [cert, setCert] = useState([]);
  const [search, setSearch] = useState('');
  const handleClose = () => setShowUrl('');
  const [filteredRecords, setFilteredRecords] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
    setSearch('')
    handleGetCertificatesRequest(e)
    dateFilter(e);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const convertDate = str => {
    str = str.toString();
    let parts = str.split(" ");
    return parts[3];
  };

  const getImgFromUrl = (logo_url, callback) => {
    var img = new Image();
    img.src = logo_url;
    img.onload = function () {
      callback(img);
    };
  }

  const generatePDF = (img, name) => {
    let options = {orientation: 'p', unit: 'mm', format: 'a5'};
    let doc = new jsPDF(options);
    doc.addImage(img, 'jpg', 0, 0, 130, 190);
    doc.save(name+".pdf");
  }

  const download = async (e) => {
    const response = await fetch(e.url);
    const pdfBlob = await response.blob();
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdfBlob);
    link.download = `${e.pdfName}.pdf`;
    link.click();
  };
  

  const dateFilter = (date) => {
    if(cert?.length > 0) {
      let year = convertDate(date);
      let filRecords = [];
      cert.map((item, index) => {
        let parts = item.Data.toString().split("-");
        console.log(year, parts[0]);
        if (parts[0] === year) {
          filRecords.push(item);
        }
      })
      setFilteredRecords(filRecords);
    }
  }

  const convertDateToItalian = (date) => {
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    options.timeZone = 'UTC';
    options.timeZoneName = 'short';
    let temp = new Date(date);

    // return temp.toLocaleString('it-IT', options);
// → "Thursday, December 20, 2012, GMT"
    return temp.toLocaleDateString();

  }

  const matchValues = (val1) => {
    return val1?.toLowerCase()?.includes(search?.toLowerCase());
  }

  const searchFilter = () => {
    if(cert.length > 0) {
      let temp = [];
      cert.map((item, index) => {
        if (
            matchValues(item.Ord[0]) ||
            matchValues(convertDateToItalian(item.Data[0])) ||
            matchValues(item.Cert[0]) ||
            matchValues(item.CdV[0]) ||
            matchValues(item.Codice[0]) ||
            matchValues(item.Descrizione[0]) ||
            matchValues(item.Norm[0]) ||
            matchValues(item.Num[0]) ||
            matchValues(item.Marcatura2[0]) ||
            matchValues(item.Marcatura[0])){
          temp.push(item);
        }
      })
      if(temp?.length > 0){
        setFilteredRecords(temp);
      }else{
        setAlertMessage(`Nessun risultato trovato per ${search}`);
        setAlert(true)
      }
    }else{
      setAlertMessage('Nessun record per l\'anno selezionato')
      setAlert(true)
    }
  }

  const resetFilters = () => {
    setFilteredRecords(cert);
  }

  const renderDateButton = () => {
    return (
        <div className="d-flex flex-column col-2 my-2" style={styles.dateBtn}>
          <Button variant="secondary" size="m-1" onClick={(e) => handleClick(e)}>
            <span style={styles.btnFontSize}>{startDate ? convertDate(startDate): 'Seleziona Anno'}</span>
          </Button>
          <div style={styles.filterText}>
            <p style={{flexWrap: 'no-wrap'}}>Seleziona Anno</p>
          </div>
          <div style={styles.datePopUp}>
            {isOpen && (
                <Datepicker selected={startDate}
                            onChange={handleChange}
                            inline showYearPicker
                            dateFormat="yyyy"
                            minDate={new Date('2020')}
                            maxDate={new Date()}
                            yearItemNumber={3}/>
            )}
          </div>
        </div>
    )
  }

  const renderSearch = () =>{
    return (
        <Form className="col-5 my-2" style={styles.search}>
          <Form.Group>
            <InputGroup >
              <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
              <Form.Control
                  type="text"
                  disabled={filteredRecords?.length === 0}
                  value={search}
                  placeholder="Ricerca"
                  onChange={(evt) => setSearch(evt.target.value)}/>
            </InputGroup>
          </Form.Group>
        </Form>
    )
  }

  const tableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <th  style={{...styles.td, minWidth: 100}}>Descrizione</th>
          <th style={styles.td}>Vostro Ordine</th>
          <th style={styles.td}>Codice</th>
          <th style={styles.td}>N.Certificato</th>
          <th style={styles.td}>Conferma Di Vendita</th>
          <th style={styles.td}>Data</th>
          <th style={styles.td}>Marcatura</th>
         
          <th style={styles.td}>Scarica</th>
          <th style={styles.td}>Visualizzazione</th>
        </tr>
      </thead>
    );
  }
  const reversed = [...filteredRecords].reverse();
  const renderData = () => {
    if(filteredRecords.length > 0) {
      return (
          <div className="border border-secondary rounded py-4 px-4 my-5"  style={styles.tableContainer}>
            <Table responsive={true}>
              <tbody style={{alignItems: 'center', justifyContent: 'center'}}>
              {reversed.map((item, index) => {
                return (
                    <tr key={index}>
                      <td style={{...styles.td, minWidth: 85, maxWidth: 80}}>
                        <p style={styles.tdFontSize}>{item?.Descrizione[0]}</p>
                      </td>
                      <td style={styles.td}>
                        <p style={styles.tdFontSize}>{item?.Ord[0]}</p>
                      </td>
                      <td style={styles.td}>
                        <p style={styles.tdFontSize}>{item?.Codice[0]}</p>
                      </td>
                      <td style={styles.td}>
                       <p style={styles.tdFontSize}>{item?.Cert[0]}</p>
                      </td>
                      <td style={styles.td}>
                       <p style={styles.tdFontSize}>{item?.CdV[0]}</p>
                      </td>
                      <td style={styles.td}>
                        <p style={styles.tdFontSize}>{convertDateToItalian(item?.Data[0])}</p>
                      </td>
                      <td style={styles.td}>
                        <p style={styles.tdFontSize}>{item?.Marcatura[0] + "  " + item?.Marcatura2[0] + "  " + item?.Num[0]}</p>
                       
                      </td>
                    
                      <td style={styles.td}>
                   
                        <Button size="sm" variant="secondary" onClick={async () => {
                          if(filteredRecords[index].url) {
                            download({url: filteredRecords[index].url, pdfName: filteredRecords[index].pdfName});
                          } else {
                            let temp = await handleGetPDFRequest('' + item?.Cert[0] + item?.Data[0][2] + item?.Data[0][3], index);
                            if(temp?.url?.length > 0) {
                              download(temp);
                            }
                          }
                        }}>
                         <FontAwesomeIcon icon={faDownload} />
                        </Button>
                      </td>
                      <td style={styles.td}>
                        <Button size="sm" onClick={async () => {
                          if(filteredRecords[index].url) {
                            setShowUrl(filteredRecords[index].url);
                          } else {
                            let temp = await handleGetPDFRequest('' + item?.Cert[0] + item?.Data[0][2] + item?.Data[0][3], index);
                            if(temp?.url?.length > 0) {
                              setShowUrl(temp?.url);
                            }
                          }
                        }}><FontAwesomeIcon icon={faExternalLinkAlt} /> </Button>
                      </td>
                    </tr>
                )
              })}
              </tbody>
            </Table>
          </div>
      )
    }else{
      return <div><span >Nessun record trovato</span></div>
    }
  }

  const renderSearchBtn = () => {
    return (
        <Button className=" col-2 my-2" variant="success" size="m-1" style={styles.headerButton} disabled={!search} onClick={(e) => searchFilter()}>
          <span style={styles.btnFontSize}>Ricerca</span>
        </Button>
    )
  }

  const renderResetBtn = () => {
    return (
        <div className={'col-2 px-3'}>
          <Button
              style={styles.headerButton}
              className="my-2"
              variant="primary"
              size="m-1"
              disabled={cert.length === filteredRecords.length || cert.length === 0}
              onClick={(e) => {
                resetFilters();
                setSearch('');
              }}>
            <span style={styles.btnFontSize}>Ripristina</span>
          </Button>

        </div>
    )
  }

  const renderModal = () => {
    return (
        <Modal as={Modal.Dialog} fullscreen={true} size={'lg'} centered show={!!showUrl} onHide={handleClose}>
          <Modal.Header>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Iframe url={showUrl? showUrl: ''}
                    width="100%"
                    height="850px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }


  const handleGetPDFRequest = (id, index) => {
    return new Promise((resolve) => {
      setLoaded(true);
      const cbSuccess = (response) => {
        setLoaded(false);
        let temp = JSON.parse(JSON.stringify(filteredRecords))
        temp[index].url = response?.data?.url;
        temp[index].pdfName = response?.data?.pdfName;
        setFilteredRecords(temp);
        resolve(response?.data);
      };
      const cbFailure = (error) => {
        setLoaded(false);
        setAlertMessage('Errore nel reperire il certificato')
        setAlert(true);
        resolve('');
        // return '';
      };
      const data = {
        id: id
      }
      dispatch(getPDF(auth?.data?.token, data, cbSuccess, cbFailure));
    });
  };

  const handleGetCertificatesRequest = (year) => {
    setLoaded(true);
    setCert({});
    const cbSuccess = (response) => {
      setLoaded(false);
      response.data.sort(function (a, b) {
        return parseInt(a.Cert) - parseInt(b.Cert);
      });
      setCert(response.data);
      setFilteredRecords(response.data);
    };
    const cbFailure = (error) => {
      setLoaded(false);
      setAlertMessage('Richiesta di rete non riuscita')
      setAlert(true);
    };
    const data = {
      year: convertDate(year)
    }
    dispatch(getCertificates(auth?.data?.token, data, cbSuccess, cbFailure));
  };

  useEffect(() => {
    handleGetCertificatesRequest(startDate);
  },[])

  return (
    <>
      <div className="d-flex  flex-wrap  align-items-center py-4">
        <Toasts message={alertMessage} show={alert} setShow={setAlert} />
        <Preloader show={loaded}/>
        <div className="d-flex flex-wrap col-12" style={styles.headerStickyContainer}>
          <div className="d-flex px-2 flex-wrap col-12 justify-content-center " style={styles.headerSticky}>
            {renderDateButton()}
            {renderSearch()}
            {renderSearchBtn()}
            {renderResetBtn()}
          </div>
          {
            filteredRecords.length > 0 && <Table className={'border border-secondary rounded'}>
              {tableHeader()}
            </Table>
          }
        </div>
        <div className="col-12 justify-content-center" style={{ justifyContent: 'center', alignItems: 'center', margin: '0 auto', marginTop: 0}}>
          {renderData()}
          {renderModal()}
        </div>
      </div>
    </>
  );
};

const styles = {
  headerSticky: {
    border: ' 1px solid #61DAFB',
    borderRadius: 10,
    marginBottom: 20,
    backgroundColor: 'white',
    paddingTop: 10
  },
  headerStickyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    margin: '0 auto ',
    backgroundColor: 'rgb(245, 248, 251)',
    paddingBottom: '0 !important',
    zIndex: 1000
  },
  headerButton: {
    height: 45,
  },
  dateBtn: {
    marginRight: '3%',
  },
  filterText: {
    alignSelf: 'center',
    marginTop: 10,
  },
  datePopUp: {
    position: 'absolute',
    marginTop: 50,
  },
  search: {
    marginRight: '2%'
  },
  tableContainer: {
    width: '100%',
    position: 'relative',
    top: -40,
    backgroundColor: 'white'

  },
  tdFontSize: {fontSize: 12},
  btnFontSize: {fontSize: 14},
  td: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    fontSize: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    textAlign: 'center',
    width: 100
  }
}