
import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope, faUnlockAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { Routes } from "../../routes";
import {validateEmail} from '../../services';
import {useDispatch} from "react-redux";
import {signUpRequest} from '../../redux/actions';


export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const validateInputs = () => {
    if (firstName.length < 3) {
      alert('Il nome deve avere più di 2 caratteri');
      return false;
    } else if (lastName.length < 3) {
      alert('Il cognome deve avere più di 2 caratteri');
      return false;
    } else if (!validateEmail(email)) {
      alert('Mail non valida');
      return false;
    } else if (password.length < 6) {
      alert('La password deve avere almeno 5 caratteri');
      return false;
    } else if (confirmPassword !== password) {
      alert('Il campo password e conferma password devono coincidere');
      return false;
    }
    return true;
  };
  const handleRequest = () => {
    if (validateInputs()) {
      const cbSuccess = (response) => {
        alert(response.message);
        history.push("/");
      };
      const cbFailure = (error) => {
        alert(error.message);
      };

      const data = new FormData();
      data.append('first_name', firstName.trim());
      data.append('last_name', lastName.trim());
      data.append('email', email);
      data.append('password', password);
      dispatch(signUpRequest(data, cbSuccess, cbFailure));
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Crea account</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Nome</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="string" placeholder="abcde" onChange={(evt) => setFirstName(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Cognome</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="string" placeholder="vwxyz" onChange={(evt) => setLastName(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>La tua mail</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" onChange={(evt) => setEmail(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>la tua password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Password" onChange={(evt) => setPassword(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>conferma password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Conferma Password" onChange={(evt) => setConfirmPassword(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100" onClick={() => handleRequest()}>
                    Iscrizione
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Hai già un account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Accedi `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
        </Container>
      </section>
    </main>
  );
};
