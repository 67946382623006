
import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faUser, faUserShield} from "@fortawesome/free-solid-svg-icons";
import {Col, Form, Button, Container, InputGroup, Card} from '@themesberg/react-bootstrap';
import {Link, useHistory} from 'react-router-dom';
import {validateEmail} from '../../services';
import {useDispatch} from "react-redux";
import {passwordRequest} from "../../redux/actions";
import {Routes} from "../../routes";


export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [agency, setAgency] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  const validateInputs = () => {
    if (firstName.length < 3) {
      alert('Inserisci più di 2 lettere');
      return false;
    } else if (lastName.length < 3) {
      alert('Inserisci più di 2 lettere');
      return false;
    } else if (!validateEmail(email)) {
      alert('Email non valida');
      return false;
    } else if (agency.length < 3) {
      alert('Nome azienda non valido');
      return false;
    }else if (phone.length < 6) {
      alert('Numero di telefono non valido');
      return false;
    }
    return true;
  };
  const handleRequest = () => {
    if (validateInputs()) {
      setLoading(true);
      const cbSuccess = (response) => {
        setLoading(false);
        alert(response.message);
        history.push("/");
      };
      const cbFailure = (error) => {
        setLoading(false);
        alert(error.message);
      };

      const data = new FormData();
      data.append('first_name', firstName.trim());
      data.append('last_name', lastName.trim());
      data.append('email', email);
      data.append('agency', agency);
      data.append('phone', phone);
      dispatch(passwordRequest(data, cbSuccess, cbFailure));
    }
  };

  return (
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Richiesta Password</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Nome</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="string" placeholder="Inserire Nome" onChange={(evt) => setFirstName(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Cognome</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="string" placeholder="Inserire Cognome" onChange={(evt) => setLastName(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>La tua mail</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="Inserire l'indirizzo mail" onChange={(evt) => setEmail(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>il tuo numero di telefono</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhone} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="phone" placeholder="Inserire il telefono" onChange={(evt) => setPhone(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Azienda</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUserShield} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="string" placeholder="Inserire il nome della propria azienda" onChange={(evt) => setAgency(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Button disabled={loading} variant="primary" type="submit" className="w-100" onClick={() => handleRequest()}>
                    {
                      loading ? 'Attendere Prego...': 'Richiesta'
                    }
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Sei pronto per accedere?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Accedi`}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Container>
        </section>
      </main>
  );
};
