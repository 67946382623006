import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Signin from "./auth/Signin";
import Signup from "./auth/Signup";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";
import PasswordRequest from './auth/PasswordRequest';


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {validateTokenRequest} from "../redux/actions";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={!loaded} /> <Component {...props} /> </> ) } />
  );
};

const validateToken = async (dispatch, auth) => {
    const cbSuccess = (response) => {
        return true;
    };
    const cbFailure = (error) => {
        return false;
    };
    dispatch(validateTokenRequest(auth?.data?.token, cbSuccess, cbFailure));
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
    const {auth} = useSelector((state) => state);
    const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => {
        if (auth?.data?.token) {
            if ( validateToken(dispatch, auth)){
                return (
                    <>
                        <Preloader show={!loaded}/>
                        <Sidebar/>

                        <main className="content">
                            <Navbar/>
                            <Component {...props} />
                        </main>
                    </>
                )
            } else {
                return <Redirect to={{pathname: '/'}} />
            }
        } else {
            return <Redirect to={{pathname: '/'}} />
        }
    }}
    />
  );
};

export default () => {
    return (
        <Switch>
            <RouteWithLoader exact path={Routes.PasswordRequest.path} component={PasswordRequest}/>
            <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview}/>
            <RouteWithLoader exact path={Routes.Signin.path} component={Signin}/>
            <RouteWithLoader exact path={Routes.Signup.path} component={Signup}/>
            <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage}/>
            <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError}/>
            <Redirect to={Routes.NotFound.path}/>
        </Switch>
    );
}
