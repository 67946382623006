
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import {useLocation} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faSignOutAlt,} from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import logo from "../assets/img/favicon/logo.jpeg";
import {useDispatch} from "react-redux";
import {logout} from "../redux/actions/auth-actions";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const dispatch = useDispatch();

  const NavItem = (props) => {
    const { title, link, external, target, onClick} = props;
    const classNames = "d-flex justify-content-start align-items-center justify-content-between" ;
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={onClick}>
        <Nav.Link {...linkProps} target={target} className={classNames} style={{padding: 5,width: 'auto'}}>
            <span className="sidebar-text" style={{width: 50}}>{title}</span>
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`} style={{width: 150}}>
          <div className="sidebar-inner px-4 pt-3">
              <Image src={logo} className="rounded-circle" style={{marginBottom: '15%'}}/>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Panoramica" link={Routes.DashboardOverview.path} icon={faChartPie} />
              <NavItem title="Disconnettersi" link={Routes.Signin} icon={faSignOutAlt} onClick={() => {
                dispatch(logout());
              }}/>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
