import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* signUpRequest() {
  yield takeLatest(types.SIGNUP_REQUEST, signup);
}

function* signup(params) {
  // console.log('[signup saga]', params);
  try {
    let response = yield Api.postAxios(endPoints.authSignUp, params.params);
    console.log('signup response', response);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }
    yield put({type: types.SET_PROFILE_DATA, data: response.data});
  } catch (err) {
    console.log('error from Sign Up request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
