import {takeLatest} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* getPdfSaga() {
  yield takeLatest(types.GET_PDF_REQUEST, getPDF);
}

function* getPDF(params) {
  try {
    console.log(params);
    let response = yield Api.getAxios(endPoints.getPDF(params.params.id));
    if (response?.data?.status) {
      params.cbSuccess(response?.data);
    } else {
      params.cbFailure(response?.data);
    }
  } catch (err) {
    console.log('error from Get PDF saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
