import {takeLatest, put} from 'redux-saga/effects';
import * as types from '../../actions/types';
import {Api} from '../../../utils';
import {endPoints} from '../../../utils';

export function* passwordRequest() {
  yield takeLatest(types.PASSWORD_REQUEST, passRequest);
}

function* passRequest(params) {
  console.log('[passReqeust saga]', params);
  try {
    let response = yield Api.postAxios(endPoints.passwordRequest, params.params);
    console.log('passRequest response', response);
    if (response?.status) {
      params.cbSuccess(response);
    } else {
      params.cbFailure(response);
    }

  } catch (err) {
    console.log('error from password request saga -- > > >  > ', err);
    params.cbFailure(err.message);
  }
}
