
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {Col, Form, Card, Button, Container, InputGroup, Image} from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {validateEmail} from '../../services';
import {validateTokenRequest, loginRequest} from '../../redux/actions';

import { Routes } from "../../routes";
import logo from "../../assets/img/favicon/logo.jpeg";


export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {auth} = useSelector((state) => state);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (auth?.data?.token) {
      validateToken();
    }
  }, []);

  const validateToken = () => {
    const cbSuccess = (response) => {
      history.replace("/dashboard");
    };
    const cbFailure = (error) => {
    };
    dispatch(validateTokenRequest(auth?.data?.token, cbSuccess, cbFailure));
  };

  const validateInputs = () => {
    if (!validateEmail(email)) {
      alert('Mail non valida');
      return false;
    } else if (password.length < 5) {
      alert('La password deve avere almeno 5 caratteri');
      return false;
    }
    return true;
  };

  const handleRequest = () => {
    if (validateInputs()) {
      setLoading(true);
      const cbSuccess = (response) => {
        console.log('response', response);
        setLoading(false);
        history.replace("/dashboard");
      };
      const cbFailure = (error) => {
        setLoading(false);
        alert(error.message);
      };

      const data = new FormData();
      data.append('email', email);
      data.append('password', password);
      dispatch(loginRequest(data, cbSuccess, cbFailure));
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className='d-flex justify-content-center '>
                  <Image src={logo} className="rounded-circle w-50 h-50"/>
                </div>
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Accedi alla nostra piattaforma</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>La tua mail</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" onChange={(evt) => setEmail(evt.target.value)}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>La tua password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" onChange={(evt) => setPassword(evt.target.value)}/>
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button disabled={loading} variant="primary" type="submit" className="w-100" onClick={() => handleRequest()}>
                    {
                      loading ? 'Attendere Prego...': 'Accedi'
                    }
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal" style={styles.textCenter}>
                   
                    <Card.Link as={Link} to={Routes.PasswordRequest.path} className="fw-bold">
                      {` Richiedi credenziali o nuova password `}
                    </Card.Link>
                  </span>
                </div>
                {/*<div className="d-flex justify-content-center align-items-center mt-4">*/}
                {/*  <span className="fw-normal">*/}
                {/*    Non sei registrato?*/}
                {/*    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">*/}
                {/*      {` Crea un account `}*/}
                {/*    </Card.Link>*/}
                {/*  </span>*/}
                {/*</div>*/}
              </div>
            </Col>
        </Container>
      </section>
    </main>
  );
};

const styles = {
  textCenter: {
    textAlign: 'center'
  }
}
