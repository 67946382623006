import {signUpRequest} from './auth-saga/signup-saga';
import {passwordRequest} from './auth-saga/passwordRequest-saga';
import {loginRequest} from "./auth-saga/login-saga";
import {validateTokenRequest} from "./auth-saga/validate-token-saga";
import {getPdfSaga} from "./pdf-saga/get-pdf-saga";
import {getCertificatesSaga} from "./pdf-saga/get-Certificates-saga";

import {fork} from 'redux-saga/effects';

export function* rootSaga() {
  yield fork(signUpRequest);
  yield fork(loginRequest);
  yield fork(validateTokenRequest);
  yield fork(getPdfSaga);
  yield fork(getCertificatesSaga);
  yield fork(passwordRequest);
}
