import * as TYPES from '../../actions/types';

const initialState = {
  data: {},
};
const authReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case TYPES.SET_PROFILE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...actions.data,
        },
      };
    case TYPES.LOGOUT:
      return {
        ...state,
        data: {...state, data: {}},
      };
    default:
      return state;
  }
};
export default authReducer;
