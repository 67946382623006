import * as TYPES from '../types';

//Sign up obj Action
export const passwordRequest = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.PASSWORD_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

export const signUpRequest = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.SIGNUP_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

export const loginRequest = (params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.LOGIN_REQUEST_REQUEST,
    params,
    cbSuccess,
    cbFailure,
  };
};

export const validateTokenRequest = (token, cbSuccess, cbFailure) => {
  return {
    type: TYPES.VALIDATE_TOKEN_REQUEST,
    token,
    cbSuccess,
    cbFailure,
  };
};


export const logout = () => {
  return {
    type: TYPES.LOGOUT,
  };
};
