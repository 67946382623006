import * as TYPES from '../types';

//Sign up obj Action
export const getPDF = (token, params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.GET_PDF_REQUEST,
    token,
    params,
    cbSuccess,
    cbFailure,
  };
};

export const getCertificates = (token, params, cbSuccess, cbFailure) => {
  return {
    type: TYPES.GET_CERTIFICATES,
    token,
    params,
    cbSuccess,
    cbFailure,
  };
};
